const CoreValues = () => {
    return ( 
        <section className="ts-service-area service-area-pattern" id="ts-service-area">
            <div className="service-area-bg">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <h2 className="section-title">Our Core Values</h2>
                        </div>
                    </div>
                    {/* <!-- Title row end--> */}
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <div className="ts-service-wrapper">
                                <div className="ts-service-box">
                                    <div className="ts-service-box-img">
                                        <img src="images/icon/why-1.png" width="70" height="70" alt="" />
                                    </div>
                                    <div className="ts-service-box-info">
                                        <h3 className="service-box-title">Responsiveness 99%</h3>
                                        <p>We priotize being responsive to the needs of our customers, employees, or partners. </p>
                                    </div>
                                </div>
                                {/* <!-- Service 1 end --> */}
                                <div className="gap-15"></div>
                                <div className="ts-service-box">
                                    <div className="ts-service-box-img">
                                        <img src="images/img.png" width="70" height="70" alt="" />
                                    </div>
                                    <div className="ts-service-box-info">
                                        <h3 className="service-box-title"> Speed of execution</h3>
                                        <p>We understand the importance of acting swiftly, By emphasizing a culture of
                                            proactive decision-making.</p>
                                    </div>
                                </div>
                                {/* <!-- Service 2 end --> */}
                                <div className="gap-15"></div>
                                <div className="ts-service-box">
                                    <div className="ts-service-box-img">
                                        <img src="images/images (6).png " width="70" height="70" alt="" />
                                    </div>
                                    <div className="ts-service-box-info">
                                        <h3 className="service-box-title">Accountability </h3>
                                        <p> We take responsibility of our actions and their outcomes, we
                                            hold ourselves accountable to the highest standards.</p>
                                    </div>
                                </div>
                                {/* <!-- Service 3 end --> */}
                            </div>
                        </div>
                        {/* <!-- Col end --> */}
                        <div className="col-lg-4 col-md-12">
                            <span className="service-img"><img className="img-fluid" src="images/estate.jpg" alt="" /></span>
                        </div>

                        <br/>
                        <div className="col-lg-4 col-md-12">
                            <div className="ts-service-wrapper ml-lg-auto">
                                <div className="ts-service-box">
                                    <div className="ts-service-box-img ">
                                        <img src="images/Value-Integrity-Icon@3x.png" width="90" height="90" alt="" />
                                    </div>
                                    <div className="ts-service-box-info">
                                        <h3 className="service-box-title">Integrity</h3>
                                        <p>Integrity form the bedrock of our organization, we are committed to
                                            conducting business honestly, ethically and transparency. </p>
                                    </div>
                                </div>
                                {/* <!-- Service 4 end --> */}
                                <div className="gap-15"></div>
                                <div className="ts-service-box">
                                    <div className="ts-service-box-img">
                                        <img src="images/images (7).png" width="70" height="70" alt="" />
                                    </div>
                                    <div className="ts-service-box-info">
                                        <h3 className="service-box-title">Doing what is right</h3>
                                        <p>Our organization is built on the belief that doing what's morally and
                                            ethically right is paramount.</p>
                                    </div>
                                </div>
                                {/* <!-- Service 5 end --> */}
                                <div className="gap-15"></div>
                                <div className="ts-service-box">
                                    <div className="ts-service-box-img">
                                        <img src="images/8990174.png" width="80" height="80" alt="" />
                                    </div>
                                    <div className="ts-service-box-info">
                                        <h3 className="service-box-title">Ethical conduct</h3>
                                        <p>We hold ourselves to the highest standards of ethical behavior, we strictly
                                            adhere to the code and conduct that promote honesty. </p>
                                    </div>
                                </div>
                                {/* <!-- Service 6 end --> */}
                            </div>
                        </div>
                        {/* <!-- Col end --> */}
                    </div>
                    {/* <!-- Content row end --> */}
                    {/* <!-- Content row end--> */}
                </div>
                {/* <!-- Container end--> */}
            </div>
        </section>
     );
}
 
export default CoreValues;