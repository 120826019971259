import { Link } from "react-router-dom";
import ContactForm from "../contact_form_section/ContactForm";

const FaqBook = () => {
    return ( 
        <section className="testimonial-area" id="testimonial-area">
            <div className="container">
                <div className="row">
                    <br/>
                    <div className="col-lg-12 qutoe-form-inner-le">
                        <ContactForm subject='Contact us now' subTitle='We are always ready' mainTitle='Contact us now'/>
                        {/* <!-- Form end--> */}
                    </div>
                    {/* <!-- Col end--> */}
                </div>
                {/* <!-- Content row end--> */}
            </div>
            {/* <!-- Container end--> */}
        </section>
     );
}
 
export default FaqBook;