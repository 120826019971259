import { Link } from "react-router-dom";

const AboutUs = () => {
    window.scrollTo(0,0);
    document.title = 'Chuks Chris Global LTD - About us';
    return ( 
        <div>
            <div className="banner-area" id="banner-area" style={{backgroundImage:'url(/images/block1.png)'}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col">
                            <div className="banner-heading">
                                <h1 className="banner-title">About Us</h1>
                                {/* <!--  --> */}
                            </div>
                        </div>
                        <ol className="breadcrumb">
                            <li>Home</li>
                            <li><Link to="#">About Us</Link></li>
                        </ol>
                        {/* <!-- Col end--> */}
                    </div>
                    {/* <!-- Row end--> */}
                </div>
                {/* <!-- Container end--> */}
            </div>
            {/* <!-- Banner area end--> */}

            <section className="main-container no-padding" id="main-container">
                <div className="about-pattern">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 about-desc">
                            <h2 className="column-title">Chuks Chris Global Ventures LTD.</h2>
                            <p className="bold-text">is a professional company responsible for overseeing and managing construction projects from start to finish. We serve as a central figure that coordinates all aspects of a building process, including supplies of General building materials such as (Tiles, Marbles, Granites, Ceramics, PVC, Gum, White Cement) and hiring of workers to ensure the project is completed on time.</p>
                            <p className="bold-text">CHUKS CHRIS GLOBAL VENTURES LIMITED specializes in the manufacturing and distribution of BERGGA FIX TILE ADHESIVE products used for fixing tiles in construction and renovation projects. The business caters to both commercial and residential markets, providing solutions for tiling floors, walls, and other surfaces. BERGGA FIX adhesive is strong, durable, and easy to work with, ensuring long-lasting installations.</p>
                            <p className="bold-text">CHUKS CHRIS GLOBAL VENTURES LIMITED is also specialized in the manufacturing and distribution of BERGGA WHITE PORTLAND CEMENT distinguished by its white color, which is achieved through meticulous manufacturing processes and the use of raw materials with low iron content. It is primarily used for aesthetic and architectural purposes where the appearance of the finished surface is of paramount importance.</p>
                            <Link to="/contact" className="top-right-btn btn btn-secondary">Contact us</Link>
                            </div>
                            {/* <!-- Col end--> */}
                            <div className="col-lg-6 text-md-center mrt-60">
                            <img className="img-fluid" src="/images/block_of_flat.jpg" width="800" height="900" alt=""/>
                            </div>
                            {/* <!-- Col end--> */}
                        </div>
                        {/* <!-- Main row end--> */}
                    </div>
                    {/* <!-- Container 1 end--> */}
                </div>
                {/* <!-- About pattern End--> */}
                </section>

            <section id="ts-features-light" className="ts-features-light">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <h2 className="section-title"><span>We are committed to you</span>Our Mission & Vision
                            </h2>
                        </div>
                    </div>
                    {/* <!-- Title row end --> */}
                    <div className="row">
                        <div className="col-lg-6 col-md-6 text-center">
                            <div className="ts-feature-box">
                            <div className="ts-feature-info">
                                <img src="/images/mission.jpg" width="400" height="300" alt=""/>
                                <h3 className="ts-feature-title">Mission</h3>
                                <p>Chuks Chris Global Ventures Ltd mission is to serve each client with integrity, excellence and efficiency. Also, to create value, positively developing, managing and investing in quality satisfaction. Our mission statement is service to humanity.</p>
                            </div>
                            </div>
                            {/* <!-- feature box-1 end--> */}
                        </div>
                        {/* <!-- col-1 end--> */}
                        <div className="col-lg-6 col-md-6 text-center">
                            <div className="ts-feature-box">
                            <div className="ts-feature-info">
                                <img src="/images/vision.jpg" width="400" height="300" alt=""/>
                                <h3 className="ts-feature-title">Vision</h3>
                                <p>To be the leading consulting firm in the building construction sector through integrity, innovations, excellence and to provide value for money invested by our client both in Nigeria and abroad.</p>
                            </div>
                            </div>
                            {/* <!-- feature box-2 end--> */}
                        </div>
                    </div>
                </div>
            </section>

        </div>
     );
}
 
export default AboutUs;