import Carousel from "./Carousel";
import CoreValues from "./CoreValues";
import FaqBook from "./FaqBook";
import { useEffect, useState } from "react";
import axios from 'axios';
import OurProperties from "./OurProperties";
import ShowProduct from "./ShowProduct";
import GetUrl from "../GetUrl";

const HomePage = () => {
    document.title = 'Chuks Chris Global LTD - Home';
    const [productData, setProductData] = useState([]);
    
    useEffect(()=>{
        const productListDetails = async ()=>{
            try {
                const res = await axios.get(GetUrl("getProductCategory"));
                setProductData([...res.data]);
            } catch (error) {
                console.log(error);
            }
        }

        productListDetails();
    },[]);

    return ( 
        <div>
            <Carousel/>
            <section id="ts-features-light" className="ts-features-light">
                <div className="container">
                    <div className="row feature-light-row">
                        <div className="col-md-4 text-center">
                            <div className="ts-feature-box">
                                <div className="ts-feature-info">
                                    <div className="feature-img">
                                        <img src="images/bc.png" width="66" height="66" alt="" />
                                    </div>
                                    <h3 className="ts-feature-title">Building Construction</h3>
                                    <p>Overseeing and managing construction projects from start to finish.</p>
                                </div>
                            </div>
                            {/* <!-- feature box-1 end--> */}
                        </div>
                        {/* <!-- col-1 end--> */}
                        <div className="col-md-4 text-center border-left">
                            <div className="ts-feature-box">
                                <div className="ts-feature-info">
                                    <div className="feature-img">
                                        <img src="images/icon/service-2.png" alt="" />
                                    </div>
                                    <h3 className="ts-feature-title">Our Services</h3>
                                    <p>Supply general building materials such as (Tiles, Marbles, Granites, Ceramics, , PVC, Gum, White Cement).</p>
                                </div>
                            </div>
                            {/* <!-- feature box-2 end--> */}
                        </div>
                        {/* <!-- col-2 end--> */}
                        <div className="col-md-4 text-center border-left">
                            <div className="ts-feature-box">
                                <div className="ts-feature-info">
                                    <div className="feature-img">
                                        <img src="images/icon/service-3.png" alt="" />
                                    </div>
                                    {/* <!--<i class="icon icon-clock3"></i>--> */}
                                    <h3 className="ts-feature-title">Affordable Price</h3>
                                    <p>Price is what you pay, value is what you get.</p>
                                </div>
                            </div>
                            {/* <!-- feature box-2 end--> */}
                        </div>
                        {/* <!-- col-3 end--> */}
                    </div>
                </div>
            </section>
            {/* <!-- ts-feature light end --> */}
            <ShowProduct/>
            <CoreValues/>
            {productData && <OurProperties productData={productData}/>}
            <FaqBook/>
        </div>
     );
}
 
export default HomePage