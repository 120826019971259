const NotFound = () => {
    document.title = 'Chuks Chris Global LTD';
    return ( 
        <div style={{
            height:'70vh',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
            }}>
            <h1 style={{color:'#c00a0a'}}>Oops! Does Not Exist</h1>
        </div>
     );
}
 
export default NotFound;