import Footer from "./pages/Footer";
import Navbar from "./pages/Navbar";
import HomePage from "./pages/homepage_section/HomePage";
import AboutUs from "./pages/aboutus_section/AboutUs";
import {Route, Routes } from "react-router-dom";
import NotFound from "./pages/not_found_section/NotFound";
import Contact from "./pages/contact_section/Contact";
import ProductList from "./pages/product_list_section/ProductList";
import ProductDetails from "./pages/product_details_section/ProductDetails";

function App() {
  return (
    <div>
      <Navbar/>
      <Routes>
        <Route exact path="/" element={<HomePage/>}/>
        <Route path="/about" element={<AboutUs/>}/>
        <Route path="/productCategory/:id" element={<ProductList/>}/>
        <Route path="/productDetails/:id" element={<ProductDetails/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
