import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import GetUrl from "./GetUrl";

const Navbar = () => {
    const [productCategory, setProductCategory] = useState([]);

    useEffect(() => {
        const getProductCategory = async ()=>{
            try {
                const res = await axios.get(GetUrl("getProductCategory"));
                setProductCategory([...res.data]);
            } catch (error) {
                console.log(error);
            }
        }

        getProductCategory();
    },[]);

    const handleMenu = () =>{
        const btnMenu = document.getElementById('btnMenu');
        btnMenu.click();
    }
    return ( 
        <div className="site-top-2">
            <header className="header nav-down" id="header-2">
                <div className="container">
                    <div className="row">
                        <div className="logo-area clearfix">
                            <div className="logo col-lg-3 col-md-3">
                                <Link to="/">
                                    <img src="/images/logokk.png" width="255" alt=""/>
                                </Link>
                            </div>
                             {/* logo end */}
                            <div className="col-lg-9 col-md-12 pull-right">
                                <ul className="top-info unstyled">
                                    <li><span className="info-icon"><i className="icon icon-phone3"></i></span>
                                        <div className="info-wrapper">
                                            <p className="info-title">24/7 Response Time</p>
                                            <p className="info-subtitle">(+234) 706-685-8958</p>
                                        </div>
                                    </li>
                                    <li><span className="info-icon"><i className="icon icon-envelope"></i></span>
                                        <div className="info-wrapper">
                                            <p className="info-title">Send Your Query</p>
                                            <p className="info-subtitle">chukschrisgloballtd@gmail.com</p>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            {/* Col End */}
                        </div>
                        {/* Logo Area End */}
                    </div>
                </div>
                {/* Container end */}
                <div className="site-nav-inner site-navigation navigation navdown">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg ">
                            <button id="btnMenu" className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation"><span
                                    className="navbar-toggler-icon"><i className="icon icon-menu"></i></span></button>
                            {/* End of Navbar toggler */}
                            <div className="collapse navbar-collapse justify-content-start" id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li><Link to="/" onClick={handleMenu}>Home</Link>
                                    </li>
                                    <li><Link to="/about" onClick={handleMenu}>About Us </Link></li>
                                    <li className="nav-item dropdown"><Link className="nav-link" to="#"
                                            data-toggle="dropdown">Our Product<i className="fa fa-angle-down"></i></Link>
                                        {productCategory && <ul className="dropdown-menu" role="menu">
                                            {
                                                productCategory.map((product)=>(
                                                  <li key={product.id}><Link to={`/productCategory/${product.category}`} onClick={handleMenu}>{product.category}</Link></li>  
                                                ))
                                            }
                                        </ul>}
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link to="/contact" onClick={handleMenu}>Contact</Link>
                                    </li>
                                </ul>
                                {/* Nav ul end */}
                            </div>
                            <Link to="/contact" className="top-right-btn btn btn-primary">Contact us</Link>
                            {/* Top bar btn */}
                        </nav>
                        {/* Collapse end */}



                    </div>
                </div>
                {/* Site nav inner end */}
            </header>
            {/* Header end */}
        </div>
     );
}
 
export default Navbar;