import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import GetUrl from "../GetUrl";
const EstateLocation = () => {
    const {id} = useParams();
    document.title = 'Product Category - ' + id;

    const [productListData, setproductListData] = useState([]);
    
    useEffect(()=>{
        const getproductList = async ()=>{
            try {
                window.scrollTo(0,0);
                
                setproductListData([]);
                const formData = new FormData();
                formData.append('category', id);

                const res = await axios.post(GetUrl("getProductList"), formData);
                setproductListData([...res.data]);
            } catch (error) {
                console.log(error);
            }
        }

        getproductList();
    },[id]);

    return ( 
    <div>
        <section className="main-container no-padding" id="main-container">
            <div className="ts-services " id="ts-services">
            <div className="container">
                <div className="row text-center">
                    <div className="col-md-12">
                        <h2 className="section-title">{id}</h2>
                    </div>
                </div>
                {/* <!-- Title row end--> */}
                
                <div className="row ts-service-row-box" id="2">
                    {productListData.length === 0 && 'Loading please wait...'}
                    {
                        productListData.map((product)=> (
                            <div className="col-lg-6 col-md-12" style={{marginBottom:'40px'}} key={product.id}>
                                <div className="ts-service-box">
                                    <div id="parent">
                                        <div className="ts-service-image-wrapper">
                                            <img className="img-fluid" src={'/product/' + product.img_name} alt="" style={{
                                                width:'100%',
                                                height:'330px'
                                            }}/>
                                        </div>
                                    </div>
                                    <div className="ts-service-content">
                                        <h3 className="service-title">{product.product_name}</h3>
                                        <p>{product.description}</p>
                                        <Link className="slider btn btn-warning btn-block" to={`/productDetails/${product.id}`}>View Details</Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    
                </div>
               
            </div>
            {/* <!-- Container end--> */}
            </div>
        </section>
        
    </div>
     );
}
 
export default EstateLocation;