import { useState } from "react";
import axios from "axios";
import GetUrl from "../GetUrl";
const ContactForm = ({subject, subTitle, mainTitle}) => {
        const [fullName, setFullName] = useState('');
        const [phone, setPhone] = useState('');
        const [email, setEmail] = useState('');
        const [mesg, setMesg] = useState('');
        const [isPending, setIsPending] = useState(false);
        const [error, setError] = useState(false);

        const submitForm = async(e) =>{
            e.preventDefault();
            setIsPending(true);
            setError(false);

            try {
                if(fullName.trim === "" || phone.trim === '' || email.trim === '' || mesg.trim === '')
                {
                    alert('Error: Fill in the details correctly!');
                }
                else
                {
                    const formData = new FormData();
                    formData.append('fullName', fullName);
                    formData.append('phone', phone);
                    formData.append('email', email);
                    formData.append('subject', subject);
                    formData.append('mesg', mesg);

                    const res = await axios.post(GetUrl("sendEmail"), formData);
                    setIsPending(false);
                    alert(res.data.mesg);
                    if(res.data.info === 'fail')
                    {
                        setError(true);
                    }
                    else
                    {
                        setFullName('');
                        setPhone('');
                        setEmail('');
                        setMesg('');
                    }
                }
            } catch (error) {
                setError(true);
            }
        }

    return ( 
        <div>
            <form className="contact-form" onSubmit={submitForm}>
                <h2 style={{textAlign:'center'}} className="column-title "><span>{subTitle}</span> {mainTitle}</h2>
                <div className="error-container"></div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <input className="form-control form-name" id="fullName" name="fullName" placeholder="Full Name"
                                type="text" value={fullName} onChange={(e)=>setFullName(e.target.value)} disabled={isPending} required/>
                        </div>
                    </div>
                    {/* <!-- Col end--> */}
                    <div className="col-lg-12">
                        <div className="form-group">
                            <input className="form-control form-phone-number" id="phone" name="phone"
                                placeholder="phone Number" type="text" value={phone} onChange={(e)=>setPhone(e.target.value)} disabled={isPending} required/>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <input className="form-control form-email" id="email" name="email" placeholder="Email"
                                type="email" value={email} onChange={(e)=>setEmail(e.target.value)} disabled={isPending} required/>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <textarea className="form-control form-message required-field" id="message"
                                placeholder="message" rows="8" value={mesg} onChange={(e)=>setMesg(e.target.value)} disabled={isPending} required></textarea>
                        </div>
                    </div>
                    {/* <!-- Col 12 end--> */}
                </div>
                {/* <!-- Form row end--> */}
                {isPending===false && <div className="text-right">
                    <button className="btn btn-warning tw-mt-30" style={{width:'100%'}} type="submit">Submit</button>
                </div>}<br/>
            </form>
            {/* <!-- Form end--> */}
            {isPending && <h6 className="font-weight-light" style={{
                textAlign:'center',
                marginBottom:'30px'
            }}>A moment please...</h6>}
            {error && <h6 className="font-weight-light" style={{
                textAlign:'center',
                marginBottom:'30px',
                color:'red'
            }}>Oops! Something went wrong. Try again</h6>}
        </div>
     );
}
 
export default ContactForm;