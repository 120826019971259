import ContactForm from "../contact_form_section/ContactForm";

const Contact = () => {
    window.scrollTo(0,0);
    document.title = 'Chuks Chris Global LTD - Contact us';
    
    return ( 
        <div>
            <div className="banner-area" id="banner-area" style={{backgroundImage:'url(/images/contact.png)'}}>
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col">
                        <div className="banner-heading">
                            <h1 className="banner-title">Contact Us</h1>
                            <ol className="breadcrumb">
                                <li>Home</li>
                                <li><a href="#">contact</a></li>
                            </ol>
                        </div>
                    </div>
                    {/* <!-- Col end--> */}
                    </div>
                    {/* <!-- Row end--> */}
                </div>
                {/* <!-- Container end--> */}
            </div>
            {/* <!-- Banner area end--> */}

            <section className="main-container contact-area" id="main-container">
                <div className="ts-form form-boxed" id="ts-form">
                    <div className="container">
                    <div className="row">
                        <div className="contact-wrapper full-contact">
                            <div className="col-lg-6">
                                <h3 className="column-title">Contact Us</h3>
                                <div className="contact-info-box contact-box info-box ">
                                <div className="contact-info">
                                    <div className="ts-contact-info"><span className="ts-contact-icon float-left"><i className="icon icon-map-marker2"></i></span>
                                        <div className="ts-contact-content">
                                            <h3 className="ts-contact-title">Find Us</h3>
                                            <p>Office Address: C7 No. 236, Int'l Market, Dei-Dei FCT-Abuja</p>
                                        </div>
                                        {/* <!-- Contact content end--> */}
                                    </div>
                                    <div className="ts-contact-info"><span className="ts-contact-icon float-left"><i className="icon icon-phone3"></i></span>
                                        <div className="ts-contact-content">
                                            <h3 className="ts-contact-title">Call Us</h3>
                                            <p>07066858958, 08182264665, 09078871718, 07057805435, 08134200969</p>
                                        </div>
                                        {/* <!-- Contact content end--> */}
                                    </div>
                                    <div className="ts-contact-info last"><span className="ts-contact-icon float-left"><i className="icon icon-envelope"></i></span>
                                        <div className="ts-contact-content">
                                            <h3 className="ts-contact-title">Mail Us</h3>
                                            <p>chukschrisgloballtd@gmail.com, chukschris295@gmail.com</p>
                                        </div>
                                        {/* <!-- Contact content end--> */}
                                    </div>
                                </div>
                                </div>
                            </div>
                            {/* <!-- Contact info end --> */}
                            <div className="col-lg-6">
                                <ContactForm subject='making enquiry' subTitle='We are always available 24/7' mainTitle='Contact us'/>
                            </div>
                            {/* <!-- Contact form end --> */}
                        </div>
                    </div>
                    </div>
                </div>
            </section>

        </div>
     );
}
 
export default Contact;