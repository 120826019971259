import { Link } from "react-router-dom";

const Footer = () => {
    return ( 
        <div>
            {/* <!-- Footer start--> */}
            <footer className="footer" id="footer">

                <div className="footer-main bg-overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12 footer-widget footer-about"><br/>
                                <div className="footer-logo">
                                    {/* <img src="/images/LOGO KK.png" height="75" alt=""/> */}
                                </div>
                                <p>Chuks chris global ventures Ltd is a professional company responsible for overseeing and managing construction projects from start to finish. We serve as a central figure that coordinates all aspects of a building process, including supplies of General building materials such as (Tiles, Marbles, Granites, Ceramics, PVC, Gum, White Cement) and hiring of workers to ensure the project is completed on time.</p>
                            </div>
                            {/* <!-- About us end--> */}
                            <div className="col-lg-4 col-md-6 footer-widget"><br/>
                                <h3 className="widget-title">Quick Links</h3>
                                <ul className="list-dash">
                                    <li><Link to="/about">About Us</Link></li>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 footer-widget"><br/>
                                <h3 className="widget-title">Company Locations</h3>
                                <div className="ts-contact-info"><span className="ts-contact-icon float-left"><i
                                            className="icon icon-map-marker2"></i></span>
                                    <div className="ts-contact-content">
                                        <h3 className="ts-contact-title">Address</h3>
                                        <p>Office Address: C7 No. 236, Int'l Market, Dei-Dei FCT-Abuja</p>
                                    </div>
                                    {/* <!-- Contact content end--> */}
                                </div>

                                <div className="ts-contact-info last"><span className="ts-contact-icon float-left"><i
                                            className="icon icon-envelope"></i></span>
                                    <div className="ts-contact-content">
                                        <h3 className="ts-contact-title">Email Address</h3>
                                        <p>chukschrisgloballtd@gmail.com, chukschris295@gmail.com</p>
                                    </div>
                                    {/* <!-- Contact content end--> */}
                                </div>

                                <div className="ts-contact-info"><span className="ts-contact-icon float-left"><i
                                            className="icon icon-phone3"></i></span>
                                    <div className="ts-contact-content">
                                        <h3 className="ts-contact-title">Phone Number</h3>
                                        <p>+234-7066858958, +234-8182264665, +234-9078871718, +234-7057805435, +234-8134200969</p>
                                    </div>
                                    {/* <!-- Contact content end--> */}
                                </div>
                            </div>
                        </div>
                        {/* <!-- Content row end--> */}
                    </div>
                    {/* <!-- Container end--> */}
                </div>
                {/* <!-- Footer Main--> */}
                <div className="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="copyright-info"><span>Powered By <a
                                href="https://www.deusfostechnologies.com" className="text-muted" target="_blank">Deusfos Technologies.</a>
                                    </span></div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="footer-social text-right">
                                    <ul>
                                        <li><Link to="https://web.facebook.com/groups/288525665146224" target="_blank"><i className="fa fa-facebook"></i></Link></li>
                                        <li><Link to="https://x.com/chukschris295?t=GpwUr7LB7hYbUogsPMvaww&s=09" target="_blank"><i className="fa fa-twitter"></i></Link></li>
                                        <li><Link to="https://youtu.be/NukuTnQKTkg?si=QNw4Ci59FVRtQBI6" target="_blank"><i className="fa fa-youtube"></i></Link></li>
                                        <li><Link to="https://www.instagram.com/chks_chris?igsh=YWNjMTJod3R3a3Jr" target="_blank"><i className="fa fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Row end--> */}
                    </div>
                    {/* <!-- Container end--> */}
                </div>
                {/* <!-- Copyright end--> */}
            </footer>
            {/* <!-- Footer end--> */}

            <div className="back-to-top affix" id="back-to-top" data-spy="affix" data-offset-top="10">
                <button className="btn btn-primary" title="Back to Top"><i className="fa fa-angle-double-up"></i>
                    {/* <!-- icon end--> */}
                </button>
                {/* <!-- button end--> */}
            </div>
            {/* <!-- End Back to Top--> */}
        </div>
     );
}
 
export default Footer;