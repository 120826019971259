const OurProperties = (props) => {
    const productData = props.productData;
    return ( 
        <section className="ts-services solid-bg" id="ts-services">
            <div className="container">
                <div className="row text-center">
                    <div className="col-md-12">
                        <h2 className="section-title"><span>We Are</span>Chuks Chris Global Ventures Limited</h2>
                    </div>
                </div>
                <div className="row ts-service-row-box" id="2">
                    <div className="col-lg-6 col-md-12" style={{marginBottom:'40px'}}>
                        <div className="ts-service-box">
                            <div id="parent">
                                <div className="ts-service-image-wrapper">
                                    <img className="img-fluid" src="images/gum.jpeg" alt="" style={{
                                        width:'100%',
                                        height:'330px'
                                    }}/>
                                </div>
                            </div>
                            <div className="ts-service-content">
                                <h3 className="service-title">BERGGA FIX GUM</h3>
                                <p>We specializes in the manufacturing and distribution of BERGGA FIX TILE ADHESIVE products used for fixing tiles in construction and renovation projects. The business caters to both commercial and residential markets, providing solutions for tiling floors, walls, and other surfaces. BERGGA FIX adhesive is strong, durable, and easy to work with, ensuring long-lasting installations.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12" style={{marginBottom:'40px'}}>
                        <div className="ts-service-box">
                            <div id="parent">
                                <div className="ts-service-image-wrapper">
                                    <img className="img-fluid" src="images/white_cement.jpeg" alt="" style={{
                                        width:'100%',
                                        height:'330px'
                                    }}/>
                                </div>
                            </div>
                            <div className="ts-service-content">
                                <h3 className="service-title">BERGGA WHITE PORTLAND CEMENT</h3>
                                <p>CHUKS CHRIS GLOBAL VENTURES LIMITED is also specialized in the manufacturing and distribution of BERGGA WHITE PORTLAND CEMENT distinguished by its white color, which is achieved through meticulous manufacturing processes and the use of raw materials with low iron content. It is primarily used for aesthetic and architectural purposes where the appearance of the finished surface is of paramount importance.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default OurProperties;