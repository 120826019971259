import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import GetUrl from "../GetUrl";
const EstateLocation = () => {
    const {id} = useParams();
    document.title = 'Product Details';

    const [productDetailsData, setProductDetailsData] = useState([]);
    
    useEffect(()=>{
        const getProductDetails = async ()=>{
            try {
                window.scrollTo(0,0);

                setProductDetailsData([]);
                const formData = new FormData();
                formData.append('id', id);

                const res = await axios.post(GetUrl("getProductDetails"), formData);
                setProductDetailsData([...res.data]);
            } catch (error) {
                console.log(error);
            }
        }

        getProductDetails();
    },[id]);

    return ( 
    <div>
        {productDetailsData.length === 0 && 'Loading please wait...'}
        {
            productDetailsData.map((product)=> (
                <div key={product.id}>
                    <div className="banner-area" id="banner-area" style={{backgroundImage:`url(/product/${ product.img_name})`}}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col">
                                    <div className="banner-heading">
                                        {/* <h1 className="banner-title">{product.product_name}</h1> */}
                                    </div>
                                </div>
                                {/* <!-- Col end--> */}
                            </div>
                            {/* <!-- Row end--> */}
                        </div>
                        {/* <!-- Container end--> */}
                    </div>
                    {/* <!-- Banner area end--> */}

                    <section className="main-container no-padding" id="main-container">
                        <div className="ts-services " id="ts-services">
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-md-12">
                                    <h2 className="section-title">{product.product_name}</h2>
                                    <p style={{fontSize:'20px'}}>{product.description}</p>
                                </div>
                            </div>
                            {/* <!-- Title row end--> */}
                            
                        </div>
                        {/* <!-- Container end--> */}
                        </div>
                    </section>
                </div>       
            ))
        }    
    </div>
     );
}
 
export default EstateLocation;