import { Link } from "react-router-dom";

const OurProperties = (props) => {
    const productData = props.productData;
    return ( 
        <section className="ts-services solid-bg" id="ts-services">
            <div className="container">
                <div className="row text-center">
                    <div className="col-md-12">
                        <h2 className="section-title"><span>What We Do</span>Our Products</h2>
                    </div>
                </div>
                {/* <!-- Title row end--> */}
                <div className="row ts-service-row-box" id="2">
                    {
                        productData.map((product)=> (
                            <div className="col-lg-6 col-md-12" style={{marginBottom:'40px'}} key={product.id}>
                                <div className="ts-service-box">
                                    <div id="parent">
                                        <div className="ts-service-image-wrapper">
                                            <img className="img-fluid" src={'product/' + product.img_name} alt="" style={{
                                                width:'100%',
                                                height:'330px'
                                            }}/>
                                        </div>
                                    </div>
                                    <div className="ts-service-content">
                                        <h3 className="service-title">{product.product_name}</h3>
                                        <p>{product.description}</p>
                                        <Link className="slider btn btn-warning btn-block" to={`/productCategory/${product.category}`}>View More</Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    
                </div>
            </div>
        </section>
     );
}
 
export default OurProperties;