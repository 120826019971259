import { Link } from "react-router-dom";

const Carousel = () => {
    return ( 
        <div className="carousel slide" id="main-slide" data-ride="carousel">
            {/* Indicators */}
            <ol className="carousel-indicators visible-lg visible-md">
                <li className="active" data-target="#main-slide" data-slide-to="0"></li>
                <li data-target="#main-slide" data-slide-to="1"></li>
                <li data-target="#main-slide" data-slide-to="2"></li>
            </ol>
            {/* Indicators end
            Carousel inner */}
            <div className="carousel-inner">
                <div className="carousel-item active" style={{backgroundImage:'url(images/block1.png)'}}>
                    <div className="container">
                        <div className="slider-content text-left">
                            <div className="col-md-12">
                                <h3 className="slide-sub-title">Chuks Chris Global Ventures LTD.</h3>
                                <p className="slider-description lead">We're responsible for overseeing and managing construction projects from start to finish</p>
                                <p><Link className="slider btn btn-primary" to="/contact">Contact Us</Link></p>
                            </div>
                            {/* <!-- Col end--> */}
                        </div>
                        {/* <!-- Slider content end--> */}
                    </div>
                    {/* <!-- Container end--> */}
                </div>
                {/* <!-- Carousel item 1 end--> */}
                <div className="carousel-item" style={{backgroundImage:'url(images/block2.png)'}}>
                    <div className="container">
                        <div className="slider-content text-center">
                            <div className="col-md-12">
                                <h2 className="slide-title title-light">Price is what you pay, value is what you get.</h2>
                                <h3 className="slide-sub-title">Chuks Chris Global Ventures LTD.</h3>
                                <p className="slider-description lead">Making your vision come true, that is what we do.
                                </p>
                                <div className="overlay"></div>
                                <p><Link className="slider btn btn-primary" to="/contact">Contact Us</Link></p>
                            </div>
                            {/* <!-- Col end--> */}
                        </div>
                        {/* <!-- Slider content end--> */}
                    </div>
                    {/* <!-- Container end--> */}
                </div>
                {/* <!-- Carousel item 2 end--> */}
                <div className="carousel-item" style={{backgroundImage:'url(images/block3.png)'}}>
                    <div className="container">
                        <div className="slider-content text-right">
                            <div className="col-md-12">
                                <h2 className="slide-title title-light">We serve as a central figure</h2>
                                <h3 className="slide-sub-title">We coordinates all aspects of a building process</h3>
                                <p><Link className="slider btn btn-primary" to="/contact">Contact Us</Link></p>
                            </div>
                            {/* <!-- Col end--> */}
                        </div>
                        {/* <!-- Slider content end--> */}
                    </div>
                    {/* <!-- Container end--> */}
                </div>
                {/* <!-- Carousel item 3 end-->  */}
            </div>
            {/* <!-- Carousel inner end--> */}
            {/* <!-- Controllers--> */}
            <a className="left carousel-control carousel-control-prev" href="#main-slide"
                data-slide="prev"><span><i className="fa fa-angle-left"></i></span></a>
            <a className="right carousel-control carousel-control-next" href="#main-slide" data-slide="next"><span><i
                        className="fa fa-angle-right"></i></span></a>
        </div>
     );
}
 
export default Carousel;